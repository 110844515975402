<script>
  import AddressBox from "../AddressBox";
  export default {
    name: "AddressManagement",
    components: {
      AddressBox
    },
    props: {
      addresses: {
        type: Array
      },
      userId: {
        type: Number
      }
    },
    data() {
      return {
        csrfToken: document.head.querySelector("[name~=csrf-token][content]").content,
        addrs: this.addresses,
        uid: this.userId,
        working: false
      }
    },
    methods: {
      newAddress() {
        let newAddr = {
          id: 0,
          user_id: this.uid,
          type: 'delivery',
          first_name: null,
          last_name: null,
          street: null,
          house_number: null,
          additional_info: null,
          zip: null,
          city: null,
          phone_number: null,
          floor: null,
          gender: null,
          company: null,
          aimeos_address_id: null,
          new: true,
          deleted: false
        }

        this.addrs.push(newAddr);
      },
      save() {
        let self = this;

        if ( !self.uid > 0 ) {
          return;
        }

        self.working = true;

        let addrsToSave = this.addrs.filter( function ( value, index, array ) {
          if ( value.deleted && value.new ) {
            return false;
          } else {
            return true;
          }
        });

        fetch('/profile/update-address', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': this.csrfToken
          },
          body: JSON.stringify({
            userId: self.uid,
            addresses: addrsToSave,
          })
        }).then(response => response.json())
            .then((responseJson) => {

            })
            .then(() => {

              self.working = false;
            }).catch(error => {
          self.working = false;
        });
      }
    }
  }
</script>

<style scoped>

</style>