<template>
    <div class="flex flex-col w-full">
        <div class="relative">
            <div ref="searchbox" class="w-full flex flex-row items-center color-white bg-white" >
                <div class="pl-2 bg-white w-full h-full rounded-lg">
                    <input v-model="query" @keypress="handleInput" @keyup="handleInput" class="h-full w-full outline-none bg-white" type="text" placeholder="Benutzer suchen">
                </div>
                <div class="cursor-pointer w-auto">
                    <span v-if="loading" class="w-auto loading-spinner"></span>
                    <slot></slot>
                </div>
            </div>
            <div ref="dropdown" v-show="isExpanded" class="autocomplete absolute z-20 flex-grow items-center color-white bg-white" >
                <div class="items-container bg-white w-full flex flex-col relative">
                    <div v-for="(result, index) in results" @click="selectUser(result)" @mousedown="updateSelectedIndex(index)" @mouseleave="selectedIndex = -1" @mouseenter="updateSelectedIndex(index)" class="flex flex-row w-full justify-between w-full p-1 cursor-pointer text-black text-xs md:text-base hover:bg-blue hover:text-white rounded-sm" :class="classForIndex(index)" v-html="handleTitle(result)"></div>
                    <div v-if="results.length == 0" class="content-center text-center justify-center">Nichts gefunden</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Events from "../support/Events";
    import getCookie from '../fn/cookie'
    export default {
        name: "SearchBox",
        props: {
            term: {
              type: String,
              default: ''
            },
            expanded: {
              type: Boolean,
              default: false
            }
        },
        data () {
            return {
                isExpanded: this.expanded,
                timeout: null,
                loading: false,
                count: 0,
                results: [],
                selectedIndex: -1,
                selected: null,
                query: this.term,
            }
        },
        computed: {

        },
        methods: {

            handleInput(e) {

                if ( e !== undefined ) {

                    switch ( e.keyCode ) {

                        case 13:
                          if ( this.selectedIndex > -1 ) {
                            //this.selectCourseByIndex(this.selectedIndex);
                          } else {
                            e.preventDefault();
                            //this.redirectSearch($(e.target).val());
                          }
                          break;

                        case 40:
                          if ( this.selectedIndex < this.results.length -1 ) {
                            this.selectedIndex++;
                          }
                          break;

                        case 38:
                          if (this.selectedIndex > 0) {
                            this.selectedIndex--;
                          }
                          break;
                        default:
                          this.$emit('select-user', null);
                    }

                }

            },

            handleTitle( result ) {

                let title = "<div class='overflow-hidden truncate'>" + result.full_name + "</div>"
                let type  = "<div class='mx-4 font-bold text-lightgrey'>" + result.id + "</div>"

                return title + type;
            },

            updateSelectedIndex(index) {
              this.selectedIndex = index;
            },

            classForIndex(index) {
              if ( index == this.selectedIndex ) {
                return "text-white bg-blue";
              }  else {
                return "text-black bg-white";
              }
            },

            isClickedOutside(e) {
                return $(this.$refs['dropdown']).find(e.target).length === 0 && ! $(e.target).is($(this.$refs['dropdown']))
                    && $(this.$refs['searchbox']).find(e.target).length === 0 && ! $(e.target).is($(this.$refs['searchbox']));
            },

            fetchResults() {
                this.loading = true;

                let csrfToken = document.head.querySelector("[name~=csrf-token][content]").content;

                fetch('/shop/search-user', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken
                  },
                  body: JSON.stringify({
                    q: this.query
                  })
                }).then(response => response.json())
                  .then((responseJson) => {
                    if ( responseJson.size > 0 ) {
                      this.results = responseJson.users;
                    } else {
                      this.results = [];
                    }
                    this.isExpanded = true;
                  })
                  .then( () => {
                    this.loading = false;

                  })
                  .catch(error => console.log(error));
            },

            selectUser(user) {
              this.isExpanded = false;
              this.selected = user;
              this.query = user.full_name;
              this.results = [];

              this.$emit('select-user', user);
            }
        },

        watch: {
            query: function(val) {
                clearTimeout(this.timeout);
                if (this.selected == null || val != this.selected.full_name) {
                  this.timeout = setTimeout(this.fetchResults, 500);
                }
            }
        },

        mounted() {

          let self = this;

          document.addEventListener('mousedown', e => {
                if (self.isClickedOutside(e)) {
                  self.isExpanded = false;
                  //self.results = [];
                }
          });
        }
    }
</script>

<style scoped>

</style>
