/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');


import Choices from "choices.js";

require('bootstrap-datepicker');


/**
 * JS
 */
require('./fn/cookie');
require('./fn/menu');


/**
 * Vue Components
 */

import SearchBox from './components/SearchBox.vue';
import ShopAssignment from './components/page/ShopAssignment';
import ShopList from "./components/ShopList";
import AddressManagement from "./components/page/AddressManagement";
import AddressBox from "./components/AddressBox";

Vue.component('search-box', SearchBox);
Vue.component('shop-assignment', ShopAssignment);
Vue.component('shop-list', ShopList);
Vue.component('address-management', AddressManagement);
Vue.component('address-box', AddressBox);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

const app = new Vue({
    el: '#app'
});

$(document).ready(function () {
    // $('.datepicker').datepicker({format: 'dd.mm.yyyy'});

    // choices.js
    if(document.querySelector('.choices')) {
        window.choices = [];
        var choices = document.querySelectorAll('.choices');

        for (var i = 0; i < choices.length; ++i) {
            if(! choices[i].id) {
                console.warn("set an ID to the choices element!")
            }

            window.choices[choices[i].id] = new Choices(choices[i], {
                searchResultLimit: 999,
            });
        }
    }
});
