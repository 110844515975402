<template>
  <div v-show="addr.deleted == false" class="panel flex flex-row">
    <div class="flex flex-col w-2/3">
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Typ:</label></div>
        <div class="w-1/2"><select class="form-control" v-model="addr.type"><option>delivery</option><option>billing</option></select></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Firma:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.company"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Geschlecht:</label></div>
        <div class="w-1/2"><select class="form-control" v-model="addr.gender"><option>male</option><option>female</option></select></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Vorname:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.first_name"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Nachname:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.last_name"></div>
      </div>
      <div class="flex flex-row justify-evenly  mb-10">
        <div class="w-1/2"><label class="control-label">Straße:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.street"></div>
      </div>
      <div class="flex flex-row justify-evenly  mb-10">
        <div class="w-1/2"><label class="control-label">Nummer:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.house_number"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Zusatz:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.additional_info"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Etage:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.floor"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">PLZ:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.zip"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Ort:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.city"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">Telefon:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.phone_number"></div>
      </div>
      <div class="flex flex-row justify-evenly mb-10">
        <div class="w-1/2"><label class="control-label">AIMEOS-AdressID:</label></div>
        <div class="w-1/2"><input class="form-control" type="text" v-model="addr.aimeos_address_id"></div>
      </div>
    </div>
    <div class="items-center content-center justify-center flex-grow w-1/3">
      <button @click="remove()">x</button>
    </div>
  </div>
</template>

<script>
  import Events from "../support/Events";
  export default {
    name: "AddressBox",
    props: {
      address: {
        type: Object
      }
    },
    data() {
      return {
        addr: this.address
      }
    },
    methods: {
      remove() {
        this.addr.deleted = true;

        Events.$emit('remove-addr', this.addr);
      }
    }
  }
</script>

<style scoped>

</style>