
window.addEventListener('load', e => {
    window.menu = new Menu();
});

window.addEventListener('click', e => {
    if ( window.menu != undefined ) {
        if (!$(e.target).is('.dropdown-toggle') && !$(e.target).is('.caret')) {
            window.menu.close();
        }
    }
});

export default class Menu {

    constructor() {

        let self = this;

        $('.dropdown-toggle').on("click", function () {
            let state = $('.dropdown-toggle').prop('aria-expanded');

            if ( state === 'true' ) {
                self.close();
            } else {
                self.open();

            }
        });

    }

    open() {
        $('.dropdown-toggle').prop('aria-expanded', 'true');
        $('.dropdown-menu').show();
    }

    close() {
        $('.dropdown-toggle').prop('aria-expanded', 'false');
        $('.dropdown-menu').hide();
    }

}