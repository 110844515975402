<script>
  import Events from "../../support/Events";
  import SearchBox from "../SearchBox";
  import ShopList from "../ShopList";
  export default {
    name: "ShopAssignment",
    components: {
      SearchBox,
      ShopList
    },
    props: {
        shops: {
          type: Array,
          default: function () { return [] }
        },
    },
    data() {
      return {
        csrfToken: document.head.querySelector("[name~=csrf-token][content]").content,
        localShops: this.shops,
        selectedUser: null,
        selectedShop: null,
        selectedShopId: 0,
        selectedShopIndex: -1,
        userShop: null,
        userShops: [],
        working: false
      }
    },
    methods: {
      userSelected() {
         return this.selectedUser != null;
      },

      addShop(id) {
        Events.$emit('add-shop', id);
      },

      fetchShopsForUser(e) {

        let self = this;

        self.selectedUser = e;

        if ( e == null ) {
          return;
        }

        self.loading = true;
        self.working = true;

        fetch('/shop/get-user-shops', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': this.csrfToken
          },
          body: JSON.stringify({
            id: e.id
          })
        }).then(response => response.json())
            .then((responseJson) => {
              this.userShops = responseJson.assigned_shops.map(value => {

                value.new = false;
                value.deleted = false;
                value.pivot = {
                  enterprise_shop_id: value.id,
                  user_id: self.selectedUser.id
                }

                return value;
              });

              self.userShop  = responseJson.main_shop;

            })
            .then( () => {
              Events.$emit('set-shops', self.userShops);
              Events.$emit('set-main', self.userShop);

              self.working = false;
            })
            .catch(error => console.log(error));
      },

      saveAssignment() {

        let self = this;

        if ( self.selectedUser == undefined ) {
          return;
        }

        self.working = true;

        let shopsToSave = this.userShops.filter( function ( value, index, array ) {
          if ( value.deleted && value.new ) {
            return false;
          } else {
            return true;
          }
        });

        fetch('/shop/save-shop-assignment', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': this.csrfToken
          },
          body: JSON.stringify({
            userId: self.selectedUser.id,
            shops: shopsToSave,
            main: self.userShop
          })
        }).then(response => response.json())
          .then((responseJson) => {

          })
          .then(() => {

            self.fetchShopsForUser(self.selectedUser);

            self.working = false;
          }).catch(error => {
            self.working = false;
        });

      },

      initEvents() {
        let self = this;

        Events.$on('update-shops', function (e) {
          self.userShops = e;
        });

        Events.$on('update-main-shop', function (e) {
          self.userShop = e;
        });
      }
    },
    mounted() {

      this.initEvents();
    }
  }
</script>
