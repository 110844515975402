<template>
  <div class="shop-list-control shop-list w-full ">
    <div v-if="visibleShops && visibleShops.length > 0">
      <div v-for="item in visibleShops" class="flex flex-row justify-between" >
        <span v-text="item.name"></span>
        <div class="flex flex-row shop-buttons-box">
          <div v-if="(userShop != null && item.id != userShop.id) || userShop == null" @click="setMain(item)" class="set-main-shop">set</div>
          <div v-if="userShop != null && item.id == userShop.id" @click="removeMain(item)" class="is-main-shop">main</div>
          <div @click="removeShop(item)" class="remove-shop">x</div>
        </div>
      </div>
    </div>
    <div v-if="visibleShops == null || ( visibleShops != null && visibleShops.length == 0 )">Keine Shops zugewiesen!</div>
  </div>
</template>

<script>
  import Events from "../support/Events";
  export default {
  name: "ShopList",
    props: {
      availableShops: {
        type: Array
      }
    },
    data() {
       return {
         selectedShopIndex: 0,
         selectedShop: null,
         userShop: null,
         userShops: [],
         visibleShops: [],
         localShops: this.availableShops
       }
    },
    methods: {
      updateVisibleShops() {

        this.visibleShops.splice(0, this.visibleShops.length);

        let shops = this.userShops.filter(function (value, index, array) {
          if (value.deleted != undefined && value.deleted === true) {
            return false;
          } else {
            return true;
          }
        });

        for ( let shop in shops ) {
          this.visibleShops.push( shops[shop] );
        }

      },
      selectShop( shop ) {
        this.selectedShop = shop;

        this.$emit('shop-selected', shop);
      },
      setMain( shop ) {
        this.userShop = shop;

        Events.$emit('update-main-shop', this.userShop);
      },
      removeMain( shop ) {
        this.userShop = null;

        Events.$emit('update-main-shop', this.userShop);
      },
      addShop( shopId ) {
        let shop = this.getShopById(shopId);
        if ( shop != null ) {
          const index = this.getUserShopIndexById(shopId);

          shop.new = true;

          if (index < 0) {
            this.userShops.push(shop);
          } else {
            this.userShops[index].deleted = false;
          }

          this.updateVisibleShops();
          Events.$emit('update-shops', this.userShops);
        }
      },
      getShopById(id) {
        for (let shop in this.localShops) {
          if ( this.localShops[shop].id == id ) {
            return this.localShops[shop];
          }
        }

        return null;
      },
      getUserShopIndexById(id) {
        for (let shop in this.userShops) {
          if ( this.userShops[shop].id == id ) {
            return shop;
          }
        }

        return -1;
      },
      removeShop( shop ) {
        const index = this.getUserShopIndexById(shop.id);

        if (index > -1) {
          if (this.userShop != null) {
            if (shop.id == this.userShop.id ) {
              this.userShop = null;
            }
          }

          if (shop.new != undefined && shop.new) {
            this.userShops.splice(index, 1);
          } else {
            this.userShops[index].deleted = true;
          }

          this.updateVisibleShops();
          Events.$emit('update-shops', this.userShops);
        }
      },
      updateSelectedShopIndex( index ) {
        this.selectedShopIndex = index;
      },
      initEvents() {
        let self = this;

        Events.$on('set-shops', function (e) {

          self.userShops.splice(0, self.userShops.length)

          for ( let shop in e ) {
            self.userShops.push( e[shop] );
          }

          self.updateVisibleShops();
        });

        Events.$on('set-main', function (e) {

          self.userShop = e;
          self.updateVisibleShops();
        });

        Events.$on('add-shop', function (e) {

          self.addShop(e);
        });
      }
    },
    mounted() {
      this.initEvents();
    }
  }
</script>

<style scoped>

</style>